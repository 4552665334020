import { FC, useState } from "react"

import { Select } from "antd"
import { useQuery } from "react-query"
import { listCinema } from "@/services"
import { useRouter } from "next/router"

import { handleSortCinemas, toggleLanguage } from "@/helper/functions"
import useContentGlobal from "@/hooks/useContentGlobal"
import dynamic from "next/dynamic"
const LoadingCustom = dynamic(() => import('../screen/common/LoadingCustom').then((mod) => mod.LoadingCustom), { ssr: false })

interface ITheaterProps {
  isHideTitle?: boolean
  isSelectMovie?: boolean
  idItems?:string
  placeholder?:string
  customPopup?:any
  customOnChange?:Function
  value?: Record<string, any>
  customClassWrap?: string
}

const SelectTheaterMobile: FC<ITheaterProps> = ({customClassWrap, customOnChange, value, isHideTitle = false, isSelectMovie = false, idItems, placeholder, customPopup}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const router = useRouter();

  const { getValueByKey } = useContentGlobal();
  const language = (typeof window !== "undefined") ? localStorage.getItem('language') ?? 'vn' : "vn";
  const defaultTitle = language === "vn" ? "Chọn rạp gần bạn" : "SELECT NEAREST CINEMA";

  const { data: dataCinemas } = useQuery(["cinema_book", {
  }], async () => {
    const resJson = await listCinema.getListCinema()
    return resJson
  }, {
    select(data: any) {
      return data?.cinemas
    }
  })

  const handleGetName = (cinema: any) => {
    const alias = toggleLanguage(cinema, "alias");
    if (alias) return alias;

    return toggleLanguage(cinema, "name");
  }
  const handleSelectChange = async (areaID: any) => {
    if(customOnChange) return customOnChange(dataCinemas?.find((el: TCinima) => el?.id == areaID))
    setIsLoading(true);
    try {
      await router.push(`/book-tickets/${areaID}`);
    } catch (error) {
      console.error('Error during redirection:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const renderContent = () => {
    if (isLoading || dataCinemas?.length === 0) return <LoadingCustom />
    let defaultValue = {
      value: "0",
      label: isSelectMovie ? getValueByKey("Select_Movie") ?? "Chọn phim" : placeholder? placeholder : getValueByKey("Select_Nearest_Cinema") ?? "Chọn rạp gần bạn"
    }
    if(idItems){
      if(!isSelectMovie) {
        const dataCinema = dataCinemas?.find((el:any) => el.id == idItems)
        if(dataCinema) defaultValue = {value: dataCinema?.id, label: handleGetName(dataCinema)}
      }
    }
    return <Select 
      loading={false}
      value={value?.value ?? defaultValue}
      className='area-theater'
      onChange={handleSelectChange}
      options={handleSortCinemas(dataCinemas)?.map((item: any) => ({
        value: item?.id,
        label: handleGetName(item),
      }))}
      popupClassName={customPopup?.popupclassname ?? ""}
      popupMatchSelectWidth={customPopup?.poppopupmatchselectwidth ?? true}
      placement="bottomRight"
      aria-label="Chọn rạp"
    />
  }

  return (
    <section>
      <div className="container">
        <div className={customClassWrap ?? "booking-cinestar-heading sec-heading"} data-aos="fade-up">
          {!isHideTitle && <div className="heading">
            <h1>{getValueByKey("Select_Nearest_Cinema") ?? defaultTitle}</h1>
          </div>}
          <div className="filter">
            <div className="select-location-second">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SelectTheaterMobile