/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */

import { ContentSection } from '@/components/pages/content';
import SelectTheaterMobile from '@/components/selectTheaterMobile';
import { toggleLanguage } from '@/helper';
import { useDetectDevice, useToggleLanguage } from '@/hooks';
import useContentGlobal from '@/hooks/useContentGlobal';
import useDataUser from '@/hooks/useDataUser';
import { useParserHtml } from '@/hooks/useParserHtml';
import { useWindowSize } from '@/hooks/userWindowSize';
import { cmsBlock, listCinema } from '@/services';
import { menu } from '@/services/list-menu';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
const MobileMenus = dynamic(() => import('./mobile-menus'), {
  ssr: false,
});
const AccountInfo = dynamic(() => import('./account-info'), {
  ssr: false,
});
const BottomNavbar = dynamic(() => import('./bottom-navbar'), {
  ssr: false,
});

type TProps = {
  topContent: any
}
export const Header = ({ topContent }: TProps) => {
  const { language, sellectLanguage } = useToggleLanguage()
  const userInfo = useDataUser();
  const isIOS = useDetectDevice();
  const router = useRouter();

  const { html } = useParserHtml(topContent?.content)
  const [content, setContent] = useState("")
  const [showPopup, setShowPopup] = useState(true)
  const panelSearchRef = useRef<any>(null)
  const inputSearchRef = useRef<any>(null);
  const { getValueByKey } = useContentGlobal();
  const [isInit, setIsInit] = useState(false)
  const { isMobile } = useWindowSize()
  const [isClient, setIsClient] = useState(false)
  // const { data: listLinkHeader } = useQuery({
  //   queryKey: ['listLinkHeader'],
  //   queryFn: async () => {
  //     return await homeApi.getListLinks()
  //   },
  //   select: (data: any) => {
  //     return data.linksCmsPage
  //   }
  // })
  const { data: listCinemas } = useQuery(["cinema_book", {
  }], async () => {
    const resJson = await listCinema.getListCinema()
    return resJson
  }, {
    select(data: any) {
      return data?.cinemas
    }
  })
  const { data: listMenu } = useQuery(["menu-header-desktop", "menu-header-mobile", "menu-footer-mobile"], async () => {
    const resJson = await menu.getListMenu()
    return resJson
  }, {
    select(data: any) {
      return data?.snowdogMenus
    }
  })

  const { data: topHeaderDownloadApp } = useQuery(
    ["cmsBlock", `top-header-download-app-${isIOS}`],
    () => cmsBlock.getCmsBlock({ "identifiers": [`top-header-download-app-${isIOS}`] }).then((res: any) => {
      const newRes = res?.cmsBlocks?.items[0]?.content
      const htmlContent = newRes?.replaceAll("&gt;", ">")?.replaceAll("&lt;", "<")
      return htmlContent
    }),
    {
      enabled: !!isIOS
    }
  )

  useEffect(() => {
    var pathArray = window.location.pathname.split('/');
    const menuLinks = document.querySelectorAll('.mobile-nav .menu-link');
    if (menuLinks) {
      menuLinks.forEach((item: any) => {
        if (item?.href.includes(pathArray[1]) && item?.href.includes("")) {
          item.closest('.menu-item').classList.add('current-menu-item')
        }

      })
    }
  }, [])

  {/* resolve error: Hydration failed  */ }
  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleToggle = (e: any, name: string) => {
    setIsInit(true)
    e.preventDefault();
    const btnName = name;
    let togglePanel = document.querySelectorAll('.togglePanel');
    togglePanel.forEach(item => {
      const panelName = (item as HTMLElement).dataset.name;
      if (btnName == panelName && item) {
        item.classList.toggle("open");
        e.currentTarget.classList.toggle("active");
      } else {
        item.classList.remove("open");
      }
    })
  }

  const handleChangeLanguage = (language: string) => {
    sellectLanguage(language)
    window.location.reload()
  }


  const handleHiddenSearch = () => {
    let togglePanel = document.querySelectorAll('.togglePanel');
    togglePanel.forEach(item => {
      item.classList.remove("open");
    })
    panelSearchRef.current?.classList?.remove("active");
  }

  const handleSearch = () => {
    inputSearchRef.current?.blur();
    router.push(`/search/?keyword=${content}`);
  }

  // const { data: cinemaData } = useQuery(["cinema"], async () => {
  //   const resJson = await fetch(`/api/cinema?id_area=0`)
  //   const res = await resJson.json()
  //   return res
  // })

  // const handleLocation = async () => {
  //   const options = {
  //     enableHighAccuracy: true,
  //     timeout: 5000,
  //     maximumAge: 0,
  //   };

  //   function tinhKhoangCach(x1: any, y1: any, x2: any, y2: any) {
  //     let khoangCach = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  //     return khoangCach;
  //   }

  //   function success(pos: any) {
  //     const crd = pos.coords;

  //     let neaer: any = null;
  //     cinemaData?.data.map((i: any) => {

  //       let b = i.maps.split(",");
  //       let latitude = b[0] * 1;
  //       let longitude = b[1].trim() * 1;

  //       let kc = tinhKhoangCach(crd.latitude, crd.longitude, latitude, longitude);
  //       if (neaer == null || kc < neaer.kc) {
  //         i.kc = kc;
  //         neaer = i;
  //       }
  //     })
  //     const cinemaItem = cinemaData?.data?.find((item: any) => item?.kc == neaer.kc)
  //     if (cinemaItem?.id) {
  //       router.push(`/book-tickets/${cinemaItem?.id}`)
  //     }
  //   }

  //   function error(err: any) {
  //     console.warn(`ERROR(${err.code}): ${err.message}`);
  //   }

  //   navigator.geolocation.getCurrentPosition(success, error, options);
  // }


  const handleClickMenu = (e: any) => {
    const mb = document.querySelector(".mobile")
    const burger = document.querySelector(".burger")
    const menuItems = document.querySelectorAll(".mobile-nav .menu-item")

    if (mb) {
      mb.classList.remove("open");
      burger?.classList.remove("active");
    }
    if (menuItems) {
      menuItems.forEach((item: any) => {
        item.classList.remove('current-menu-item');
      })
    }
    e.currentTarget.classList.add('current-menu-item')

  }
  const handleClosePopup = useCallback(() => {
    const hdTopElement = document.querySelector(".hd") as HTMLDivElement
    hdTopElement.classList.add("--second")
    if (topContent && showPopup) hdTopElement.classList.add("--third")
    document.documentElement.style.setProperty('--sz-hd-top', '.5rem');
    // const element = document.querySelector(".app-main") as HTMLHtmlElement
    // if(element) element.style.paddingTop = "calc(var(--sz-hd-mid) + var(--sz-hd-bot) + .5rem)"
    // document.documentElement.style.setProperty('--sz-hd', 'calc(var(--sz-hd-mid) + var(--sz-hd-bot))');
    // const root = document.querySelector(':root');
    // const sizeHdMid = getComputedStyle(root!).getPropertyValue("--sz-hd-mid")
    // const sizeHdBot = getComputedStyle(root!).getPropertyValue("--sz-hd-bot")
    // document.documentElement.style.setProperty('--sz-hd', `calc(${sizeHdMid} + ${sizeHdBot} + 4rem)`);
    setShowPopup(false)
  }, [showPopup, topContent])


  useEffect(() => {
    const buttonClosePopup = document.querySelector(".popup-download-app .hd-top-close") as HTMLDivElement
    if (buttonClosePopup) {
      buttonClosePopup.addEventListener("click", () => {
        handleClosePopup()
      })
    }
  }, [handleClosePopup, topHeaderDownloadApp])
  useEffect(() => {
    if (showPopup) {
      topContent && document.documentElement.style.setProperty('--sz-hd', 'calc(var(--sz-hd-mid) + var(--sz-hd-bot) + var(--sz-hd-top) + var(--sz-hd-top)');
      const hdTopElement = document.querySelector(".hd") as HTMLDivElement
      if (router.asPath !== "/") {
        hdTopElement.classList.add("--second")
        document.documentElement.style.setProperty('--sz-hd-top', '.5rem');
      } else {
        hdTopElement.classList.remove("--second")
        document.documentElement.style.setProperty('--sz-hd-top', '5.8rem');
      }
    }
  }, [showPopup, topContent, router])

  const handleGetMenu = (identifier: string) => {
    return listMenu?.items?.find((el: any) => el.identifier == identifier)?.nodes?.items
  }

  return (
    <>
      <header className={clsx("hd", topContent && showPopup ? "" : !topContent || showPopup && "hd-no-top")}>
        {topContent && (
          <motion.div
            initial={{ translateY: '-100%' }}
            animate={{ translateY: 0 }}
            transition={{ duration: .7 }}
          >
            <ContentSection content={html} />
          </motion.div>
        )}
        {/* <div className="hd-top">
          <div className="container">
            <div className="hd-top-wr">
              <div className="hd-top-content">
                <span className="txt">
                  Giảm 50% giá vé cho các cụm rạp mới, từ ngày 20/12/2024.
                </span>
                <a className="link" href="">
                  Tìm hiểu thêm!
                </a>
              </div>
              <div className="hd-top-close" onClick={()=>{}}>
                <img src="/assets/images/header-ic-close.svg" alt="" />
              </div>
            </div>
          </div>
        </div> */}
        {/*<div className="hd-top">
          <div className="container">
            <div className="hd-top-wr">
            <div className='popup-top-logo'>
                <img src='https://cinestar.com.vn/pictures/logo/favicon.ico' alt='' />
                <div className='popup-top-content popup-download-app'>
                  <div className='text'> <span className="txt">
                    Tải ứng dụng CineStar Cinema.
                  </span>
                    <span className='txt txt-sub'>
                      Tra cứu lịch chiếu và đặt vé siêu nhanh
                    </span></div>
                  <div className="link"><a href='#'>{getValueByKey("Open") ?? "Mở"}</a></div>

                </div>
              </div>
              <div className="hd-top-close is-desktop" onClick={handleClosePopup}>
                <img src="/assets/images/header-ic-close.svg" alt="" />
              </div>
            </div>
          </div>
        </div> */}
        <div className={`popup-download-app ${showPopup && router.asPath == "/" && 'min-h-[58px]'}`} dangerouslySetInnerHTML={{ __html: topHeaderDownloadApp }}></div>
        <div className="hd-mid">
          <div className="container">
            <div className="hd-mid-wr">
              <Link href='/' className="custom-logo-link" aria-label="The logo of Cinestar">
                <Image src="/assets/images/header-logo.png" alt="CineStar Logo" fill onClick={handleClickMenu} className="!static" />
              </Link>
              <div className="hd-action">
                <Link legacyBehavior href={"/movie"}>
                  <a className={clsx("btn btn--pri", router.asPath === "/movie/" && "btn--pri-active")}>
                    <Image width={24} height={24} loading="lazy" sizes="(max-width: 768px) 50vw, 100vw" src="/assets/images/ic-ticket.svg" alt="" />
                    <span className="txt">{getValueByKey("Buy_Tickets_Now") ?? "Đặt vé ngay"}</span>
                  </a>
                </Link>
                <Link legacyBehavior href={"/popcorn-drink"}>
                  <a className="btn btn--second">
                    <Image width={24} height={24} loading="lazy" sizes="(max-width: 768px) 50vw, 100vw" src="/assets/images/ic-cor.svg" alt="" />
                    <span className="txt">{getValueByKey("Buy_Popcorn") ?? "Đặt Bắp Nước"}</span>
                  </a>
                </Link>
              </div>
              <div className="hd-mid-right">
                <div className="hd-search">
                  <div className="hd-search-icon toggleBtn" onClick={(e) => handleToggle(e, "panel-search")}>
                    <Image width={15} height={15} loading="lazy" sizes="(max-width: 768px) 50vw, 100vw" src="/assets/images/icon-search.svg" alt="" />
                  </div>
                  <div className={clsx(
                    "hd-search-block togglePanel",
                    !topContent || !showPopup && "hd-search-block-no-top"
                  )} data-name="panel-search">
                    <div className="hd-search-form">
                      <div >
                        <div className="hd-search-wr">
                          <input
                            value={content}
                            className="re-input !bg-white"
                            onKeyPress={(e) => {
                              if (e.code == "Enter") {
                                handleSearch()
                                handleToggle(e, "panel-search")
                              }
                            }}
                            onChange={(e: any) => setContent(e.target.value)}
                            type="text"
                            placeholder={getValueByKey("Search_Placeholder")}
                            ref={inputSearchRef}
                          />
                          <button className="hd-search-form-btn" aria-label='Button search' type="submit" onClick={(e) => {
                            handleSearch()
                            handleToggle(e, "panel-search")
                          }}>
                            <Image width={15} height={15} loading="lazy" sizes="(max-width: 768px) 50vw, 100vw" src="/assets/images/ic-header-search.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={clsx('search-bg-blur', !topContent && "search-bg-blur-no-top")} onClick={handleHiddenSearch} />
                  </div>
                </div>
                <div className='select-location-header display-mobile'><SelectTheaterMobile placeholder={getValueByKey("Select_Theater") ?? "Chọn rạp"} customPopup={{ popupclassname: "select-localtion-header__popup", poppopupmatchselectwidth: false }} isHideTitle idItems={router.asPath.split("/").at(-2)} /></div>
                <div className="hd-regi min-w-[120px] desktop">
                  {/* resolve error: Hydration failed  */}
                  {isClient ? <AccountInfo userInfo={userInfo} getValueByKey={getValueByKey} /> :
                    <div className="hd-regi-wr">
                      <div className="hd-regi-ava">
                        <Image width={32} height={32} loading="lazy" sizes="(max-width: 768px) 50vw, 100vw" src="/assets/images/ic-header-auth.svg" alt="" />
                      </div>
                      <div className="hd-regi-list">
                        <Link className="link" href={"/login/?prevUrl=" + router.asPath} > <i className="fa-regular fa-user"></i>{getValueByKey("Sign_In") ?? "Đăng nhập"} </Link>
                        {/* <span className="dot">/</span>
                      <Link className="link" href="/register"> <i className="fa-regular fa-user"></i>{getValueByKey("Sign_Up") ?? "Đăng ký"} </Link> */}
                      </div>
                    </div>}
                </div>
                <div className="hd-lg">
                  <div className="lg-action">
                    <div className="lg-popup">

                      {/* <div className="lg-option">
                        <span className="image">
                          <img src="/assets/images/footer-vietnam.svg" alt="" />
                        </span>
                        <span className="txt">VN</span>
                      </div> */}

                      {language == "vn" ?
                        <div className="lg-option">
                          <span className="image">
                            <Image width={24} height={24} src={"/assets/images/footer-vietnam.svg"} alt="" />
                          </span>
                          <span className="txt">VN</span>
                          <i className="fas fa-caret-down"></i>
                        </div>
                        : <div className="lg-option">
                          <span className="image">
                            <Image width={24} height={24} src={"/assets/images/footer-america.webp"} alt="" />
                          </span>
                          <span className="txt">ENG</span>
                          <i className="fas fa-caret-down"></i>
                        </div>
                      }
                    </div>
                    <div className="lg-action-popup">
                      {language == "vn" && isClient ?
                        <div className="lg-option" onClick={() => { handleChangeLanguage("en") }}>
                          <span className="image">
                            <Image width={24} height={24} src={"/assets/images/footer-america.webp"} alt="" />
                          </span>
                          <span className="txt">ENG</span>
                        </div>
                        : <div className="lg-option" onClick={() => { handleChangeLanguage("vn") }}>
                          <span className="image">
                            <Image width={24} height={24} src={"/assets/images/footer-vietnam.svg"} alt="" />
                          </span>
                          <span className="txt">VN</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="hd-burger">
                  <div className="burger toggleBtn" onClick={(e) => handleToggle(e, "panel-mobile")}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                    <svg width="100%" height="100%" viewBox="-1 -1 102 102">
                      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hd-bot">
          <div className="container">
            <div className="hd-bot-wr hd-regi-wr">
              <div className='hd-bot-action-wrap'>
                <div className="hd-regi">
                  <div className=" --second">
                    <span className='hd-bot-loca'>
                      <i className="fa-sharp fa-regular fa-location-dot"></i>
                      <span className="txt">
                        {getValueByKey("Select_Theater") ?? "Chọn rạp"}
                      </span>
                    </span>
                    <div className="hd-regi-list hd-regi-list-custom --second custom-position-left">
                      {listCinemas?.map((item: any) => {
                        // const isScrollToElement = router.asPath == "/" 
                        return <Link key={item.area_id + item.id} legacyBehavior scroll={true} href={`/book-tickets/${item.id}`}>
                          <a className="link" href={`/book-tickets/${item.id}`}>
                            {toggleLanguage(item, "name") || ""}
                          </a>
                        </Link>
                      })}
                    </div>
                  </div>
                </div>
                <div className='hd-bot-action-left'>
                  <Link className='hd-bot-loca' href="/showtimes">
                    <i className="fa-calendar fa-regular fa-location-dot"></i>
                    <span className="txt">
                      {getValueByKey("Showtime") ?? "Lịch chiếu"}
                    </span>
                  </Link>
                </div>
              </div>


              <div className="hd-bot-km">
                {handleGetMenu("menu-header-desktop")?.map((item: any, index: number) => {
                  return <Link key={index} className="link" href={item.url_key ?? "/"}>
                    {item?.title}
                  </Link>
                })}

              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mobile togglePanel" data-name="panel-mobile">

        {isInit && <MobileMenus handleGetMenu={handleGetMenu}
          handleClickMenu={handleClickMenu}
          getValueByKey={getValueByKey}
          language={language}
          isClient={isClient}
          handleChangeLanguage={handleChangeLanguage} />}

      </div>
      {isMobile && isClient && <BottomNavbar handleHiddenSearch={handleHiddenSearch} getValueByKey={getValueByKey} />}

    </>
  )
}
