import { useState } from "react";

const INIT_POPUP = {
  isOpen: false,
  message: ""
}

const useStatePopupNotify = () => {
  const [popupNotify, setPopupNotify] = useState(INIT_POPUP);

  const handleOpenNotify = (message: string) => {
    return setPopupNotify({
      isOpen: true,
      message: message,
    })
  }

  const handleClosedNotify = () => {
    return setPopupNotify(INIT_POPUP);
  }


  return {
    popupNotify, 
    handleOpenNotify,
    handleClosedNotify
  };
}

export default useStatePopupNotify