// @ts-ignore
// import VNnum2words from "vn-num2words";
import moment from 'moment'
// import { baseFile } from "~/api";

class Format {
	// format date
	getVNDate = (date: Date, format: string = 'DD/MM/YYYY h:mm:ss A') => moment(date).format(format)

	getShortVNDate = (date: Date) => moment(date).format('DD/MM/YYYY')

	// kiểm tra có phải là số hay không
	isNumber = (val: string) => {
		if (val.match(/^-?[0-9]\d*([,.]\d+)?$/)) return true
		return false
	}

	// format ngày giờ

	converseDateTime = (val: number | any) => {
		return val == 0 ? '' : moment(val * 1000.0).format('DD/MM/YYYY HH:mm:ss')
	}

	converseDate = (val: number | any) => {
		return moment(val * 1000.0).format('DD/MM/YYYY')
	}

	converseDay = (val: number | any) => {
		return moment(val * 1000.0).format('DD')
	}

	converseYear = (val: number | any) => {
		return moment(val * 1000.0).format('YYYY')
	}

	// chuyển ngày giờ về dạng dãy số
	converseDateNumber = (val: string | any) => {
		const newD = Math.floor(new Date(val).getTime() / 1000.0)
		return newD
	}

	// format tiền việt nam
	getVND = (price: number, suffix: string = '') => (price?.toString() || '0').replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (suffix||" VNĐ")

	// format phần trăm
	getPercent = (price: number, suffix: string = ' %') => (price?.toString() || '0') + suffix
}

export const _format = new Format()

export function removeEmptyValueFromObject<T>(obj: T extends object ? object : any): T {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== '')) as T
}
// hàm thêm số 0 vào số nhỏ hơn 10
export const zeroFill = (n: any) => {
	return n < 10 ? ('0' + n).slice(-2) : n
}
