import { FC, useEffect, useState } from 'react'
import { toggleLanguage } from '@/helper/functions'

type TProps = {
  res: any
}

export const ContentPage: FC<TProps> = ({ res }) => {
  const [isClient, setIsClient] = useState(false)
  useEffect(()=>{
    setIsClient(true)
  },[])
  
  return (
    <div id='html-body' dangerouslySetInnerHTML={{ __html: isClient ? toggleLanguage(res?.res, "content")?.htmlContent :  res?.res?.content_vn?.htmlContent }}></div> 
  )
}
export const ContentSection = ({ content }: { content: string }) => {
  
  return (
    <div dangerouslySetInnerHTML={{ __html: content }}></div>
  )
}