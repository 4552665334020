import { TUser } from "@/types/user";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { handleDecode, SECRET_KEY } from "@/helper/encode";

const DEFAULT_VALUE_USER = {
	id: '',
	name: '',
	phone: '',
	email: '',
	image: '',
	gender: '',
	address: '',
	account: '',
  password: '',
	birthday: '',
	card_type: '',
	card_type_id: '',
	total_points: '',
	identification: '',
	accumulate_points: ''
}

export const useDataUser = () => {
  const userTemp = useSelector(
    (state: RootState) => state.userTemp
  )

  const user = useSelector(
    (state: RootState) => state.user
  );
  // console.log('usertemp', userTemp?.userInfo, 'user',user?.userInfo)

  const userInfo = handleDecode<TUser>( userTemp?.userInfo||user?.userInfo, SECRET_KEY);


  if(userInfo === false) return DEFAULT_VALUE_USER;
  return userInfo;
}

export default useDataUser;