import moment from 'moment'
import { useEffect, useState } from 'react'

export default function useSavaSessionKey() {
  const [sessionKey, setSessionKey] = useState<string>('')

  useEffect(() => {
     const key: any = localStorage.getItem('session_key')
		if (!key || key == '') {
			const date = moment().unix()
			const text_random = Math.random().toString(36).substring(2, 7)
			const session_key = `${date}${text_random}`
			localStorage.setItem('session_key', session_key)
      setSessionKey(session_key)
		} else {
			setSessionKey(key)
		}
  }, [sessionKey])

  return { session_key: sessionKey }
}
