import { zeroFill } from '@/utils/format'
import moment from 'moment'

export const correctEmail = (value: string) => {
	;/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
}

export const correctPassword = (value: string) => {
	return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(value)
}

export const isVietnamesePhoneNumberValid = (num: string) => {
	return /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(num)
}

export const checkNumberPhone = (number: string) => {
	;/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(number)
}

export const onScrollBottom = (callBack: Function) => {
	window.onscroll = function (event) {
		if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
			callBack(event)
		}
	}
}

export const getHoursName = (hours: number): string => {
	const hoursVal = ((hours % 1) * 10 * 6).toFixed(0)
	if (hours < 1) return `${hoursVal} Phút`
	return `${hours | 0} Giờ ${hoursVal} Phút`
}

export function isValidHttpUrl(string: string): boolean {
	let url

	try {
		url = new URL(string)
	} catch (_) {
		return false
	}
	return url.protocol === 'http:' || url.protocol === 'https:'
}

export const spliceArray = (arr: Array<any>, start: number, end: number) => {
	return [...arr].splice(start, end)
}

export const FormatNumber = (money: number, separator = ',') => {
	if (!money) return '0'
	return (money + '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator)
}

export function formatMoneyVND(num: number | string, format = 'đ'): string {
	if (typeof num == 'number') {
		num = Math.floor(num)
		return `${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${format}`
	} else if (typeof num == 'string') {
		return `${num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${format}`
	}
	return ''
}

export const toFirstUpperCase = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const toFirstLowerCase = (string: string) => {
	return string.charAt(0).toLowerCase() + string.slice(1)
}

export function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height
	}
}

export const getFromLocalStorage: any = (key: string) => {
	let value
	try {
		if (typeof window === undefined) return
		value = JSON.parse(window.localStorage.getItem(key) as any)
	} catch (error) {}
	return value
}

export const setToLocalStorage: any = (key: string, value: any) => {
	if (typeof window === undefined) return
	window.localStorage.setItem(key, JSON.stringify(value))
}

export const getFromSessionStorage: any = (key: string) => {
	let value
	try {
		if (typeof window === undefined) return
		value = JSON.parse(window.sessionStorage.getItem(key) as any)
	} catch (error) {}
	return value
}

export const setToSessionStorage = (key: string, value: any) => {
	if (typeof window === undefined) return
	window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function convertBase64(file: File) {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.readAsDataURL(file)

		fileReader.onload = () => resolve(fileReader.result)
		fileReader.onerror = (error) => reject(error)
	})
}

export function convertViToEn(str: string, toUpperCase = false) {
	str = str.toLowerCase()
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
	str = str.replace(/đ/g, 'd')
	// Some system encode vietnamese combining accent as individual utf-8 characters
	str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
	str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư

	return toUpperCase ? str.toUpperCase() : str
}

export const calculateElapsedTime = (timeCreated: string) => {
	const created = new Date(timeCreated).getTime()
	let periods: any = {
		year: 365 * 30 * 24 * 60 * 60 * 1000,
		month: 30 * 24 * 60 * 60 * 1000,
		week: 7 * 24 * 60 * 60 * 1000,
		day: 24 * 60 * 60 * 1000,
		hour: 60 * 60 * 1000,
		minute: 60 * 1000
	}
	let diff = Date.now() - created

	for (const key in periods) {
		if (diff >= periods[key]) {
			let result = Math.floor(diff / periods[key])
			return `${result} ${result === 1 ? key : key + 's'} ago`
		}
	}

	return 'Just now'
}

export const toggleBodyOverflow = (status: 'hidden' | 'unset') => {
	const body = document.body
	if (body) {
		if (status === 'hidden') {
			body.classList.add('body-hidden')
		} else {
			body.classList.remove('body-hidden')
		}
	}
}

export const convertToEnNoSpaceAndSpecialCharacter = (address: string) => {
	return address.replace(/\W/g, '')
}

export const formatMoneyVndString = (number: number): string => {
	if (number < 1000000) return formatMoneyVND(number)

	if (number < 1000000000) return (number / 1000000).toFixed(0) + ' triệu'

	return (number / 1000000000).toFixed(0) + ' tỷ'
}

export const toImageUrl = (url: string) => `${process.env.NEXT_PUBLIC_API_URL_MAGENTO}${url}`

export const getActiveStringOrListString = (a: string[] | string, b: string[] | string): boolean => {
	if (typeof a !== typeof b) return false
	if (typeof a === 'string') {
		return a === b
	}
	if (typeof a === 'object') {
		return a.length === b.length && a.every((item, index) => item === b[index])
	}

	return false
}

export const toggleLanguage = (props: any, fields: string) => {
	if (typeof window === 'undefined') return props?.[`${fields}_${'vn'}`]
	const language = localStorage.getItem('language') || 'vn'
	const newFields = `${fields}_${language}`
	return props?.[newFields]
}

export type TDDate = {
	value: string
	label_en: string
	label_vn: string
	date: string
}

export const getListDate = (start: string, end: string) => {
	let result: TDDate[] = []
	let currentDate: any = new Date(start)

	while (currentDate <= new Date(end)) {
		result.push({
			value: moment(currentDate)?.format('DD') + '/' + moment(currentDate)?.format('MM'),
			label_en: currentDate.toLocaleDateString('en-US', { weekday: 'long' }),
			label_vn: currentDate.toLocaleDateString('vi-VN', { weekday: 'long' }),
			date: moment(currentDate)?.format('MM/DD/YYYY HH:mm:ss')
		})
		currentDate.setDate(currentDate.getDate() + 1)
	}

	return result
}

export const isEmail = (input: string): boolean => {
	const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return emailRegex.test(input)
}

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const regex = /^(84|0[3-9]|0[1-2][0-9]|0[5-8][0-9]|09)[0-9]{8}$/;
  return regex.test(phoneNumber);
}

export const convertDate = (date: string) => {
	if (!date) return
	const parts: any = date?.split('/')
	const dateObject = new Date(parts[2], parts[1] - 1, parts[0])
	const day = dateObject.getDate()
	const monthIndex = dateObject.getMonth() + 1
	const formattedDate = zeroFill(day) + '/' + zeroFill(monthIndex)
	const ObjDate = {
		label_vn: dateObject.toLocaleDateString('vi-VN', { weekday: 'long' }),
		label_en: dateObject.toLocaleDateString('en-US', { weekday: 'long' }),
		date: moment(dateObject)?.format('MM/DD/YYYY HH:mm:ss'),
		value: formattedDate
  }
	return ObjDate
}

export const handleSortCinemas = (dataSort: TCinima[]) => {
  return dataSort?.sort((a: TCinima, b: TCinima) => {
    const orderbyA = parseInt(a.orderby, 10);
    const orderbyB = parseInt(b.orderby, 10);

    if (orderbyA < orderbyB) return -1;
    if (orderbyA > orderbyB) return 1;
    return 0;
  });
}
