import dynamic from 'next/dynamic'
import Image from 'next/image'
// import Image from 'next/image'
import Blur from "@/public/assets/images/mobile-blur.webp"
import Blur2 from "@/public/assets/images/mobile-blur-2.webp"
import React from 'react'
import { useWindowSize } from '@/hooks/userWindowSize'

type TProps = {
  children?: React.ReactNode | any
}
const PopupPromotion = dynamic(() => import('@/components/screen').then((mod) => mod.PopupPromotion), { ssr: false })

export const Main: React.FC<TProps> = ({ children }) => {
  const { isMobile } = useWindowSize()
  return (
    <React.Fragment>
      <main className={'app-main'}>
        <PopupPromotion />
        {!isMobile && <div className="main-dc">
          <Image src={Blur}
            alt="main-dc" width={1} height={1} className="bg-blur bg-blur-1" loading='eager' fetchPriority='high' priority />
          <Image src={Blur2}
            alt="main-dc" width={1} height={1} className="bg-blur bg-blur-2" loading='eager' fetchPriority='high' priority />
        </div>}

        <div className="main-dc-1"></div>
        <div className="main-dc-2"></div>
        <div className="main-dc-3"></div>
        <div className="main-dc-4"></div>
        <div className="app-content"> {children}</div>
      </main>
    </React.Fragment>
  )
}
