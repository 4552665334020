import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'

export const refactored555 = gql`
	query refactored555($identifiers: [String!]) {
		contentTranslate(identifiers: $identifiers) {
			content
			identifier
		}
	}
`

const handleContentGlobal = async (input: { identifiers: string[] }) => {
  const language = (typeof window !== "undefined") ? localStorage.getItem('language') ?? 'vn' : "vn";
  graphQLClient.setHeader('store', language);
	return await graphQLClient.request(refactored555, input)
}

const contentGlobalAPI = {
	get: handleContentGlobal
}

export default contentGlobalAPI
