import { AES, enc } from 'crypto-js'

export const SECRET_KEY = 'CINESTAR-MONA'

export const handleEncode = (value: any, secretKey: string): string => {
	const message = JSON.stringify(value)
	const encrypted = AES.encrypt(message, secretKey)
	return encrypted.toString()
}

export const handleDecode = <T = any>(code: any, secretKey: string): T | false => {
	try {
		const decrypted = AES?.decrypt?.(code, secretKey)
		const format = decrypted?.toString?.(enc?.Utf8) ?? ''

		const original = JSON?.parse?.(format)
		return original
	} catch {
		return false
	}
}
