import { useEffect, useState } from "react";

function useDetectDevice() {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isAppleDevice = /iPad|iPhone|iPod/i.test(userAgent);
    setIsIOS(isAppleDevice);
  }, []);

  return isIOS ? "ios" : "android";
}
export {useDetectDevice}