import { gql } from 'graphql-request'
import { graphQLClientGet } from './graphql'

export const getListMenu = gql`
query snowdogMenus($identifiers: [String]) {
  snowdogMenus(identifiers: $identifiers) {
    items {
      creation_time
      css_class
      identifier
      menu_id
      nodes {
        items {
          additional_data
          classes
          creation_time
          level
          menu_id
          node_id
          node_template
          parent_id
          position
          submenu_template
          title
          type
          update_time
          url_key
          ... on SnowdogMenuNodeImageFieldInterface
          {
            image
            image_alt_text
          }
        }
      }
      title
      update_time
    }
  }
}
`
export const menu = {
	getListMenu: async () => {
		try {
			return await graphQLClientGet.request(getListMenu)
		} catch (error) {
			console.error('Error get list post:', error)
		}
	}
}