import { TlayoutWithChild } from '@/types/layout'
import { Header } from './header'
import { Main } from './main'
// import { Footer } from './footer'
import { cmsBlock } from '@/services'
import { useQuery } from 'react-query'
// dynamic import Footer component with ssr = false
import dynamic from 'next/dynamic'
import React, { ReactElement } from 'react'
const Footer = dynamic(() => import('./footer').then((mod) => mod.Footer), { ssr: false })

type TProps = {
  breadcrumb?: string
  userPage?: boolean
  children?: React.ReactNode | any
}

export const MainLayout: TlayoutWithChild & React.FC<TProps> = ({ children }) => {
  const { data } = useQuery(
    ["cmsBlockLayout"],
    () => cmsBlock.getCmsBlock({ "identifiers": ["footer-content", "top-content"] }).then((res: any) => {
      return res?.cmsBlocks?.items
    }))

  return (
    <div className="app">
      <Header topContent={data?.find((item: any) => item?.identifier == "top-content")} />
      <Main>{children}</Main>
      <Footer content={data?.find((item: any) => item?.identifier == "footer-content")}  />
    </div>
  )
}

MainLayout.getLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>
