import { useQuery } from 'react-query'
import contentGlobalAPI from '@/services/contentGlobal'

type DataGlobal = {
	contentTranslate?: {
		content: string
		identifier: string
	}[]
}
const useContentGlobal = () => {
	const { data: dataGlobal, status } = useQuery({
		queryKey: ['GET_CONTENT_GLOBAL'],
		queryFn: async () => {
			const data: any = await contentGlobalAPI.get({ identifiers: ['global'] })
			return (data as DataGlobal)?.contentTranslate?.[0]
		},
		staleTime: 30000
	})

	const dataGlobalParse = dataGlobal?.content ? JSON.parse(dataGlobal.content) : {}

	const getValueByKey = (key: string | string[]) => {
		if (typeof key === 'string') return dataGlobalParse?.[key]
		return key?.reduce((accumulator: any, currentValue: string) => accumulator?.[currentValue], dataGlobalParse) ?? ""
	}

	return {
		data: dataGlobalParse,
		getValueByKey,
    status
	}
}

export default useContentGlobal
