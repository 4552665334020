import { useEffect, useState } from 'react'

export const useToggleLanguage = () => {
  const [language, setLanguage] = useState<string>(typeof window !== 'undefined' ? localStorage.getItem('language') || 'vn' : 'vn')

	const sellectLanguage = (props: string) => {
		setLanguage(props)
	}

	useEffect(() => {
		localStorage.setItem('language', language)
	}, [language])

	return {
		language,
		sellectLanguage
	}
}
